<template>
    <div>

        <div id="tray">
            <button id="doer" @click="doThings">do it</button>
            <div>id: {{panoId}}</div>
            <div id="resulting">{{url}}</div>
        </div>
        <div ref="map" id="map"></div>
        <div ref="pano" id="pano"></div>
    </div>
</template>

<script>

    var panorama, map;
    var APIkey= "AIzaSyBEsibCCfPosP9Q4uK6edsf6XsFkiAYFDQ";

window.checkAndAttachMapScript = function (callback) {
   let scriptId = "map-api-script";
   let mapAttached = !!document.getElementById(scriptId);

   if (mapAttached) {
      if (window.google) // Script attached but may not finished loading
         callback();
   }
   else {
      window.mapApiInitialized = callback;

      let script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${APIkey}&callback=mapApiInitialized`;
      document.body.appendChild(script);
   }

   return mapAttached;
}

export default {
  name: "AdminStreetview",
  components: {
  },
  props: {
  },
  data() {
    return {
        url: null,
        panoId: null,
    };
  },
  async mounted() {
        console.log('mounted');
    window.checkAndAttachMapScript(this.initialize);
  },
  methods: {
    
    initialize() {
        console.log('init', this.$refs.map);
        var fenway = {
            lat: 42.345573,
            lng: -71.098326
        };
        var agbar = new google.maps.LatLng(41.4035482, 2.1894355);
        map = new google.maps.Map(this.$refs.map, {
            center: agbar,
            zoom: 14
        });
        panorama = new google.maps.StreetViewPanorama(
            this.$refs.pano, {
                position: agbar,
                pov: {
                    heading: 34,
                    pitch: 10
                }
            });
        map.setStreetView(panorama);
    },

    doThings() {
        this.panoId = panorama.getPano();
        this.url = "https://maps.googleapis.com/maps/api/streetview?size=640x640" +"&pano=" + panorama.getPano() + "&heading=" + panorama.getPov().heading + "&pitch=" + panorama.getPov().pitch + "&fov="+ (180/ (Math.pow(2, panorama.getZoom()?panorama.getZoom():1)))+ "&key=" + APIkey;
      //use the next line to open in a new tab the resulting image at max size (640x640)
//        window.open("https://maps.googleapis.com/maps/api/streetview?size=640x640" + "&pano=" + panorama.getPano() + "&heading=" + panorama.getPov().heading + "&pitch=" + panorama.getPov().pitch + "&fov="+ (180/ (Math.pow(2, panorama.getZoom())))+ "&key=" +APIkey)
    }

  },
  computed: {
  },

};
</script>
<style lang="scss">

    #map,
    #pano {
        /*float: left;*/
        height: 400px;
        width: 100%;
    }
</style>

